import {
  Component,
  computed, DestroyRef,
  OnInit,
  signal,
  WritableSignal
} from '@angular/core';
import {ShowroomCardComponent} from "../showroom-card/showroom-card.component";
import {CarouselComponent} from "../../carousel/carousel.component";
import {WheelThrottleModule} from "../../../directives/wheelThrottleDirective/wheel-throttle.module";
import {NgClass} from "@angular/common";
import {ApplyFnModule} from "../../../pipes/applyFn/apply-fn.module";
import {BreakpointObserver, BreakpointState} from "@angular/cdk/layout";
import {filter, map, takeUntil} from "rxjs";
import {SkeletonModule} from "primeng/skeleton";
import {HomepageService} from "../../../../services/homepage/homepage.service";
import {takeUntilDestroyed} from "@angular/core/rxjs-interop";

interface SlidesConfig {
  perView: number;
  spacing: number;
  loop?: boolean;
}

interface CarouselConfig {
  breakpoints: {
    [key: string]: {
      slides: SlidesConfig;
    };
  };
  drag: boolean;
  loop: boolean;
}

@Component({
  selector: 'app-more-to-explore',
  standalone: true,
  imports: [
    ShowroomCardComponent,
    CarouselComponent,
    WheelThrottleModule,
    NgClass,
    ApplyFnModule,
    SkeletonModule,
  ],
  templateUrl: './more-to-explore.component.html',
  styleUrl: './more-to-explore.component.scss'
})
export class MoreToExploreComponent implements OnInit {

  constructor(
    public breakpointObserver: BreakpointObserver,
    public homepageService: HomepageService,
    private destroyRef:  DestroyRef) {}

  carouselConfig!: CarouselConfig;

  showroomList = computed(() => this.homepageService.SHOWROOM_LIST())

  showroomListForCarousel = computed(() => {
    if(this.showroomList()?.length > this.itemsPerView() && this.showroomList.length < this.itemsPerView() * 2){
      return [...this.showroomList(),...this.showroomList()]
    }else{
      return this.showroomList();
    }
  })

  itemsPerView:  WritableSignal<number> = signal<number>(0);

  ngOnInit() {
    this.carouselConfig = this.getCarouselConfig(this.showroomList().length);
    this.observeBreakpoint();
  }

  observeBreakpoint() {
    this.breakpointObserver
      .observe(Object.keys(this.carouselConfig.breakpoints))
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        filter((state: BreakpointState) => state.matches),
        map((state: BreakpointState) =>
          Object.keys(state.breakpoints).find(key => state.breakpoints[key]) ?? null
        ),
        filter((breakpoint: string | null) => breakpoint !== null)
      )
      .subscribe((breakpoint: string | null) => {
        if (breakpoint) {
          this.itemsPerView.set(Math.floor(this.carouselConfig.breakpoints[breakpoint]?.slides.perView));
        }
      });
  }

  getCarouselConfig(showroomLength: number): CarouselConfig {
    const getSlidesConfig = (viewport: number): SlidesConfig => {
      let config: SlidesConfig = {
        perView: 1,
        spacing: 0
      };
      if(viewport > 991) {
        config.perView = showroomLength === 1 ? 1 : showroomLength === 2 ? 2 : 3;
        config.spacing = showroomLength === 1 ? 0 : showroomLength === 2 ? 24 : 15;
      } else if(viewport <= 991 && viewport > 767) {
        config.perView = showroomLength === 1 ? 1 : 2;
        config.spacing = showroomLength === 1 ? 0 : showroomLength === 2 ? 24 : 15;
      } else {
        config.perView = showroomLength === 1 ? 1 : 1.215;
        config.spacing = showroomLength === 1 ? 0 : 12.93;
        config.loop = true;
      }
      return config;
    };

    return {
      breakpoints: {
        "(max-width: 80000px) and (min-width: 991px)": {
          slides: getSlidesConfig(80000),
        },
        "(max-width: 990px) and (min-width: 768px)": {
          slides: getSlidesConfig(991),
        },
        "(max-width: 767px)": {
          slides: getSlidesConfig(767),
        },
      },
      drag: false,
      loop: true,
    };
  }


  /**
   * Scroll carousel through wheel
   * @param event
   * @param carouselRef
   */
  carouselScrollManually(event: WheelEvent, carouselRef: CarouselComponent) {
    if (event.deltaX > 0) {
      carouselRef.nextPage();
    } else if (event.deltaX < 0) {
      carouselRef.previousPage();
    }
  }
}
