import {
  afterNextRender,
  AfterRenderPhase,
  Component,
  OnDestroy,
  signal,
  WritableSignal
} from '@angular/core';
import {HomepageService} from "../../../services/homepage/homepage.service";
import {getWebLink} from "../../../utils/common-function";
import {FooterService} from "../../../services/footer/footer.service";
import {ApplyFnModule} from "../../pipes/applyFn/apply-fn.module";
import {AsyncPipe, isPlatformBrowser} from "@angular/common";
import {ActivatedRoute} from "@angular/router";
import {Subscription} from "rxjs";

@Component({
  selector: 'app-book-a-stay-cta',
  standalone: true,
  imports: [
    ApplyFnModule,
    AsyncPipe
  ],
  templateUrl: './book-a-stay-cta.component.html',
  styleUrl: './book-a-stay-cta.component.scss'
})
export class BookAStayCtaComponent {
  private queryParamSubscription!: Subscription;
  constructor(
    public homepageService: HomepageService,
    public footerService: FooterService,
    ){
  }

  get showroomDetails() {
    return this.homepageService.PROPERTY_DETAILS?.showroomDetails
  }

  protected readonly getWebLink = getWebLink;

}
