import { __assign, __extends } from "tslib";
import { FetchTransport } from '@amplitude/analytics-client-common';
import { Config, Logger } from '@amplitude/analytics-core';
import { LogLevel } from '@amplitude/analytics-types';
import { DEFAULT_SAMPLE_RATE, DEFAULT_SERVER_ZONE } from '../constants';
export var getDefaultConfig = function () {
  return {
    flushMaxRetries: 2,
    logLevel: LogLevel.Warn,
    loggerProvider: new Logger(),
    transportProvider: new FetchTransport()
  };
};
var SessionReplayLocalConfig = /** @class */function (_super) {
  __extends(SessionReplayLocalConfig, _super);
  function SessionReplayLocalConfig(apiKey, options) {
    var _this = this;
    var _a;
    var defaultConfig = getDefaultConfig();
    _this = _super.call(this, __assign(__assign({
      transportProvider: defaultConfig.transportProvider
    }, options), {
      apiKey: apiKey
    })) || this;
    _this.flushMaxRetries = options.flushMaxRetries !== undefined && options.flushMaxRetries <= defaultConfig.flushMaxRetries ? options.flushMaxRetries : defaultConfig.flushMaxRetries;
    _this.apiKey = apiKey;
    _this.sampleRate = options.sampleRate || DEFAULT_SAMPLE_RATE;
    _this.serverZone = options.serverZone || DEFAULT_SERVER_ZONE;
    _this.configServerUrl = options.configServerUrl;
    _this.trackServerUrl = options.trackServerUrl;
    _this.shouldInlineStylesheet = options.shouldInlineStylesheet;
    _this.version = options.version;
    _this.performanceConfig = options.performanceConfig;
    _this.storeType = (_a = options.storeType) !== null && _a !== void 0 ? _a : 'idb';
    if (options.privacyConfig) {
      _this.privacyConfig = options.privacyConfig;
    }
    if (options.debugMode) {
      _this.debugMode = options.debugMode;
    }
    if (options.experimental) {
      _this.experimental = options.experimental;
    }
    return _this;
  }
  return SessionReplayLocalConfig;
}(Config);
export { SessionReplayLocalConfig };
