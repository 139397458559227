import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';
import {ShowroomsComponent} from "./showrooms.component";
import {OurFavouritesComponent} from "./our-favourites/our-favourites.component";
import {LocalItemsComponent} from "./local-items/local-items.component";
import {SpacesComponent} from "./spaces/spaces.component";
import {AllProductsComponent} from "./all-products/all-products.component";
import {HomepageComponent} from "./homepage/homepage.component";
import {NgbDropdownModule} from "@ng-bootstrap/ng-bootstrap";
import {AnimateOnScrollModule} from "primeng/animateonscroll";
import {SkeletonModule} from "primeng/skeleton";
import {PlaceholderImageModule} from "../shared/directives/placeholderImageDirective/placeholder-image.module";
import {WheelThrottleModule} from "../shared/directives/wheelThrottleDirective/wheel-throttle.module";
import {CoBrandingModule} from "../shared/display-data/co-branding/co-branding.module";
import {ApplyFnModule} from "../shared/pipes/applyFn/apply-fn.module";
import {ImageSizeModule} from "../shared/pipes/imageSize/image-size.module";
import {ProductDetailViewModule} from "../shared/display-data/product-detail-view/product-detail-view.module";
import {ProductViewModule} from "../shared/display-data/product-view/product-view.module";
import {DiscoverSpacesModule} from "../shared/display-data/discover-spaces/discover-spaces.module";
import {TitleDisplayModule} from "../shared/display-data/title-display/title-display.module";
import {RouterLink} from "@angular/router";
import {ExternalLinkPopupModule} from "../shared/display-data/external-link-popup/external-link-popup.module";
import { CarouselComponent } from '../shared/display-data/carousel/carousel.component';
import {FirstImpressionDirective} from "../shared/directives/firstImpression/first-impression.directive";
import {AffiliateUrlDirective} from "../shared/directives/affilateUrlDirective/affiliate-url.directive";
import {MoreToExploreComponent} from "../shared/display-data/more-to-explore/more-to-explore/more-to-explore.component";

@NgModule({
  declarations: [
    ShowroomsComponent,
    OurFavouritesComponent,
    LocalItemsComponent,
    SpacesComponent,
    AllProductsComponent,
    HomepageComponent,
  ],
    imports: [
        CommonModule,
        // ShowroomRoutingModule,
        NgbDropdownModule,
        ProductDetailViewModule,
        FormsModule,
        PlaceholderImageModule,
        CoBrandingModule,
        ProductDetailViewModule,
        DiscoverSpacesModule,
        WheelThrottleModule,
        ApplyFnModule,
        AnimateOnScrollModule,
        SkeletonModule,
        TitleDisplayModule,
        ImageSizeModule,
        ProductViewModule,
        RouterLink,
        ExternalLinkPopupModule,
        CarouselComponent,
        FirstImpressionDirective,
        AffiliateUrlDirective,
        MoreToExploreComponent,
    ],
  exports : [
    ShowroomsComponent,
    OurFavouritesComponent,
    LocalItemsComponent,
    SpacesComponent,
    AllProductsComponent,
    HomepageComponent,
  ]
})
export class ShowroomsModule {
}
