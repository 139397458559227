import {Component, Inject, OnDestroy, OnInit, PLATFORM_ID} from '@angular/core';
import {HomepageService} from "../../services/homepage/homepage.service";
import {SpaceDetails} from "../../interfaces/spaceDetails";
import {ActivatedRoute} from "@angular/router";
import {FooterService} from "../../services/footer/footer.service";
import {debounceTime, Subscription} from "rxjs";
import {AmplitudeService} from "../../services/tracking/amplitude.service";
import {isPlatformBrowser} from "@angular/common";
import {SkimlinksService} from "../../services/tracking/skimlinks.service";

@Component({
  selector: 'app-spaces',
  templateUrl: './spaces.component.html',
  styleUrl: './spaces.component.scss'
})
export class SpacesComponent implements OnInit, OnDestroy {
  spaceDetails: SpaceDetails | undefined;
  spaceId: string = '';
  routeSub: Subscription;
  routeSub2: Subscription;

  constructor(
    public homepageService: HomepageService,
    public activatedRoute: ActivatedRoute,
    public footerService: FooterService,
    public amplitudeService: AmplitudeService,
    public skimlinksService: SkimlinksService,
    @Inject(PLATFORM_ID) public platformId: Object,
  ) {
    this.routeSub = this.activatedRoute.params.subscribe(params => {
      if(Object.keys(params).length !== 0 && !!params['spaceId']){
        this.spaceId = params['spaceId'];
        this.spaceDetails = this.SPACE_DETAILS;
        this.amplitudeService.screenImpressionEvent('space_page', this.spaceDetails?.title!)
        this.homepageService.updateMetaAndTitleTags(`Shop Your Stay - ${this.spaceDetails?.title!} | ${this.homepageService.PROPERTY_DETAILS?.showroomDetails.showroomName!}`, this.spaceDetails?.image!)
        if(isPlatformBrowser(this.platformId)) {
          this.skimlinksService.loadAffliateLinks(`${this.footerService.PropertyId}-${this.footerService.PropertyDetails.id}`);
          const element = document.getElementById('spaceImage')
          if(element) {
            element.classList.remove('fadeInEffect');
          }
        }
      }
    })

    this.routeSub2 = this.activatedRoute.params.pipe(debounceTime(100)).subscribe({
      next: () => {
        if(isPlatformBrowser(this.platformId)) {
          const element = document.getElementById('spaceImage')
          if(element) {
            element.classList.add('fadeInEffect');
          }
        }
      }
    })

  }

  ngOnInit() {
  }

  get showroomDetails() {
    return  this.homepageService.PROPERTY_DETAILS?.showroomDetails;
  }


  /**
   * Get all Select space details
   */
  get SPACE_DETAILS(): SpaceDetails | undefined {
    return this.homepageService?.PROPERTY_DETAILS?.spaceList.find((space:SpaceDetails) => space.subspaceName === this.spaceId);
  }

  /**
   * Destroy hook
   */
  ngOnDestroy() {
    this.routeSub.unsubscribe();
    this.routeSub2.unsubscribe();
  }

}
