@if (this.homepageService.isPageLoaded) {
  <app-header [isInnerPage]="isInnerPage"></app-header>
}
  <div class="main_wrapper">
    <router-outlet></router-outlet>
  </div>
@if (this.homepageService.isPageLoaded) {
  <app-footer></app-footer>
}
<app-book-a-stay-cta></app-book-a-stay-cta>


