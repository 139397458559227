<section class="space_page">
  <section class="inner_page_banner" [ngClass]="{'no_image': !spaceDetails?.image!}">
    <div class="mx-1660 container">
      <div class="inner_banner_sec">
        <div class="left_sec">
          <div class="hero_desc">
            <a routerLink="/{{footerService.PropertyId}}" class="back-btn link-primary-btn p-0">
              <span class="el-icon">
                <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M25.1875 20.5H14.25M14.25 20.5L19.5 15.25M14.25 20.5L19.5 25.75" stroke="#666666" stroke-width="1.3125" stroke-linecap="round" stroke-linejoin="round"/>
                  <g opacity="0.3">
                  <circle cx="20" cy="20" r="19.75" transform="matrix(-1 0 0 1 40 0)" stroke="black" stroke-width="0.5"/>
                  </g>
                  </svg>

              </span>
<!--              <p class="el-text">HOME</p>-->
            </a>
            <h1 class="heading_1">{{ spaceDetails?.title }}</h1>
          </div>
        </div>
        <div class="right_sec">
          <figure class="hero_img_sec fadeInEffect" id="spaceImage">
            <img
              src="{{spaceDetails?.image!}}"
              appPlaceholderImgDirective [type]="'space'"
              srcset="{{spaceDetails?.image}} 1500w,
                      {{spaceDetails?.image! | imageSize: '_hero_desk'}} 1250w,
                      {{spaceDetails?.image! | imageSize: '_hero_phone'}} 1000w,
                     "
              sizes="(max-width: 500px) 500px, (max-width: 1000px) 1000px, (max-width: 1500px) 1250px, 1500px"
              alt="{{spaceDetails?.title!}}" />
          </figure>
        </div>
      </div>
    </div>
  </section>
  <!-- Filter and Product Listing -->
  <app-product-view [spaceDetails]="spaceDetails!"></app-product-view>
  <!-- End Filter and Product Listing -->
  <app-discover-spaces pageType="space_page" [currentSpaceId]="spaceDetails?.subspaceName!"></app-discover-spaces>
  <!--Co Branding Section Start-->
  <app-co-branding></app-co-branding>
  <!--Co Branding Section End-->
  <!--more showrooms to explore starts-->
  @if(showroomDetails?.curatedBy === 'account_name'){
    <app-more-to-explore></app-more-to-explore>
  }
</section>
