import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FooterComponent} from "./footer.component";
import {ReactiveFormsModule} from "@angular/forms";
import {NgxCaptchaModule} from "ngx-captcha";


@NgModule({
  declarations: [FooterComponent],
    imports: [
        CommonModule,
        ReactiveFormsModule,
        NgxCaptchaModule
    ],
  exports :[
    FooterComponent
  ]
})
export class FooterModule { }
