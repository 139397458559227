import { __assign, __awaiter, __generator } from "tslib";
import { getStorageSize } from '../helpers';
import { SessionReplayTrackDestination } from '../track-destination';
import { SessionReplayEventsIDBStore } from './events-idb-store';
import { InMemoryEventsStore } from './events-memory-store';
export var createEventsManager = function (_a) {
  var config = _a.config,
    sessionId = _a.sessionId,
    minInterval = _a.minInterval,
    maxInterval = _a.maxInterval,
    type = _a.type,
    payloadBatcher = _a.payloadBatcher,
    storeType = _a.storeType;
  return __awaiter(void 0, void 0, void 0, function () {
    function flush(useRetry) {
      if (useRetry === void 0) {
        useRetry = false;
      }
      return __awaiter(this, void 0, void 0, function () {
        return __generator(this, function (_a) {
          return [2 /*return*/, trackDestination.flush(useRetry)];
        });
      });
    }
    var trackDestination, getMemoryStore, getIdbStoreOrFallback, store, _b, sendEventsList, sendCurrentSequenceEvents, sendStoredEvents, addEvent;
    return __generator(this, function (_c) {
      switch (_c.label) {
        case 0:
          trackDestination = new SessionReplayTrackDestination(__assign(__assign({}, config), {
            loggerProvider: config.loggerProvider,
            payloadBatcher: payloadBatcher
          }));
          getMemoryStore = function () {
            return new InMemoryEventsStore({
              loggerProvider: config.loggerProvider,
              maxInterval: maxInterval,
              minInterval: minInterval
            });
          };
          getIdbStoreOrFallback = function () {
            return __awaiter(void 0, void 0, void 0, function () {
              var store;
              return __generator(this, function (_a) {
                switch (_a.label) {
                  case 0:
                    return [4 /*yield*/, SessionReplayEventsIDBStore.new(type, {
                      loggerProvider: config.loggerProvider,
                      minInterval: minInterval,
                      maxInterval: maxInterval,
                      apiKey: config.apiKey
                    }, sessionId)];
                  case 1:
                    store = _a.sent();
                    config.loggerProvider.log('Failed to initialize idb store, falling back to memory store.');
                    return [2 /*return*/, store !== null && store !== void 0 ? store : getMemoryStore()];
                }
              });
            });
          };
          if (!(storeType === 'idb')) return [3 /*break*/, 2];
          return [4 /*yield*/, getIdbStoreOrFallback()];
        case 1:
          _b = _c.sent();
          return [3 /*break*/, 3];
        case 2:
          _b = getMemoryStore();
          _c.label = 3;
        case 3:
          store = _b;
          sendEventsList = function (_a) {
            var events = _a.events,
              sessionId = _a.sessionId,
              deviceId = _a.deviceId,
              sequenceId = _a.sequenceId;
            if (config.debugMode) {
              getStorageSize().then(function (_a) {
                var totalStorageSize = _a.totalStorageSize,
                  percentOfQuota = _a.percentOfQuota,
                  usageDetails = _a.usageDetails;
                config.loggerProvider.debug("Total storage size: ".concat(totalStorageSize, " KB, percentage of quota: ").concat(percentOfQuota, "%, usage details: ").concat(usageDetails));
              }).catch(function () {
                // swallow error
              });
            }
            trackDestination.sendEventsList({
              events: events,
              sessionId: sessionId,
              flushMaxRetries: config.flushMaxRetries,
              apiKey: config.apiKey,
              deviceId: deviceId,
              sampleRate: config.sampleRate,
              serverZone: config.serverZone,
              version: config.version,
              type: type,
              onComplete: function () {
                return __awaiter(void 0, void 0, void 0, function () {
                  return __generator(this, function (_a) {
                    switch (_a.label) {
                      case 0:
                        return [4 /*yield*/, store.cleanUpSessionEventsStore(sessionId, sequenceId)];
                      case 1:
                        _a.sent();
                        return [2 /*return*/];
                    }
                  });
                });
              }
            });
          };
          sendCurrentSequenceEvents = function (_a) {
            var sessionId = _a.sessionId,
              deviceId = _a.deviceId;
            store.storeCurrentSequence(sessionId).then(function (currentSequence) {
              if (currentSequence) {
                sendEventsList({
                  sequenceId: currentSequence.sequenceId,
                  events: currentSequence.events,
                  sessionId: currentSequence.sessionId,
                  deviceId: deviceId
                });
              }
            }).catch(function (e) {
              config.loggerProvider.warn('Failed to get current sequence of session replay events for session:', e);
            });
          };
          sendStoredEvents = function (_a) {
            var deviceId = _a.deviceId;
            return __awaiter(void 0, void 0, void 0, function () {
              var sequencesToSend;
              return __generator(this, function (_b) {
                switch (_b.label) {
                  case 0:
                    return [4 /*yield*/, store.getSequencesToSend()];
                  case 1:
                    sequencesToSend = _b.sent();
                    sequencesToSend && sequencesToSend.forEach(function (sequence) {
                      sendEventsList({
                        sequenceId: sequence.sequenceId,
                        events: sequence.events,
                        sessionId: sequence.sessionId,
                        deviceId: deviceId
                      });
                    });
                    return [2 /*return*/];
                }
              });
            });
          };
          addEvent = function (_a) {
            var event = _a.event,
              sessionId = _a.sessionId,
              deviceId = _a.deviceId;
            store.addEventToCurrentSequence(sessionId, event.data).then(function (sequenceToSend) {
              return sequenceToSend && sendEventsList({
                sequenceId: sequenceToSend.sequenceId,
                events: sequenceToSend.events,
                sessionId: sequenceToSend.sessionId,
                deviceId: deviceId
              });
            }).catch(function (e) {
              config.loggerProvider.warn('Failed to add event to session replay capture:', e);
            });
          };
          return [2 /*return*/, {
            sendCurrentSequenceEvents: sendCurrentSequenceEvents,
            addEvent: addEvent,
            sendStoredEvents: sendStoredEvents,
            flush: flush
          }];
      }
    });
  });
};
